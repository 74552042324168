/* eslint-disable no-magic-numbers */

import { nlNL } from '@mui/material/locale';
import { createTheme, darken, lighten, Theme } from '@mui/material/styles';
import { TypographyOptions, TypographyStyleOptions } from '@mui/material/styles/createTypography';
import { CSSProperties } from 'react';

declare module '@mui/material/styles' {
  interface PaletteOptions {
    other: {
      // highContrast: string;
      // mediumContrast: string;
      // lowContrast: string;
      component1: string;
      component2: string;
      plainWhite: string;
      outlines: string;
      disabled: string;
      disabledText: string;
    };
  }

  interface TypographyVariants {
    'h1-bold': CSSProperties;
    'h1-italic': CSSProperties;
    'h2-bold': CSSProperties;
    'h2-italic': CSSProperties;
    'h3-bold': CSSProperties;
    'h3-italic': CSSProperties;
    'h4-bold': CSSProperties;
    'h4-italic': CSSProperties;

    'body/regular': CSSProperties;
    'body/regular-bold': CSSProperties;
    'body/small': CSSProperties;
    'body/small-bold': CSSProperties;
    'body/extraSmall': CSSProperties;
    'body/extraSmall-bold': CSSProperties;

    'btn-label/big-light': CSSProperties;
    'btn-label/big-italic': CSSProperties;
    'btn-label/big-bold': CSSProperties;
    'btn-label/regular-light': CSSProperties;
    'btn-label/regular-light-underlined': CSSProperties;
    'btn-label/regular-italic': CSSProperties;
    'btn-label/regular-light-strike-through': CSSProperties;
    'btn-label/regular-bold': CSSProperties;
    'btn-label/regular-bold-underlined': CSSProperties;
    'btn-label/small-light': CSSProperties;
    'btn-label/small-strike-through': CSSProperties;
    'btn-label/small-underlined': CSSProperties;
    'btn-label/small-bold': CSSProperties;
    'btn-label/small-bold-underline': CSSProperties;
    'btn-label/extraSmall': CSSProperties;
    'btn-label/extraSmall-bold': CSSProperties;
  }

  // Allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    'h1-bold': TypographyStyleOptions;
    'h1-italic': TypographyStyleOptions;
    'h2-bold': TypographyStyleOptions;
    'h2-italic': TypographyStyleOptions;
    'h3-bold': TypographyStyleOptions;
    'h3-italic': TypographyStyleOptions;
    'h4-bold': TypographyStyleOptions;
    'h4-italic': TypographyStyleOptions;

    'body/regular': TypographyStyleOptions;
    'body/regular-bold': TypographyStyleOptions;
    'body/small': TypographyStyleOptions;
    'body/small-bold': TypographyStyleOptions;
    'body/extraSmall': TypographyStyleOptions;
    'body/extraSmall-bold': TypographyStyleOptions;

    'btn-label/big-light': TypographyStyleOptions;
    'btn-label/big-italic': TypographyStyleOptions;
    'btn-label/big-bold': TypographyStyleOptions;
    'btn-label/regular-light': TypographyStyleOptions;
    'btn-label/regular-light-underlined': TypographyStyleOptions;
    'btn-label/regular-italic': TypographyStyleOptions;
    'btn-label/regular-light-strike-through': TypographyStyleOptions;
    'btn-label/regular-bold': TypographyStyleOptions;
    'btn-label/regular-bold-underlined': TypographyStyleOptions;
    'btn-label/small-light': TypographyStyleOptions;
    'btn-label/small-strike-through': TypographyStyleOptions;
    'btn-label/small-underlined': TypographyStyleOptions;
    'btn-label/small-bold': TypographyStyleOptions;
    'btn-label/small-bold-underline': TypographyStyleOptions;
    'btn-label/extraSmall': TypographyStyleOptions;
    'btn-label/extraSmall-bold': TypographyStyleOptions;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    'h1-bold': true;
    'h1-italic': true;
    'h2-bold': true;
    'h2-italic': true;
    'h3-bold': true;
    'h3-italic': true;
    'h4-bold': true;
    'h4-italic': true;

    'body/regular': true;
    'body/regular-bold': true;
    'body/small': true;
    'body/small-bold': true;
    'body/extraSmall': true;
    'body/extraSmall-bold': true;

    'btn-label/big-light': true;
    'btn-label/big-italic': true;
    'btn-label/big-bold': true;
    'btn-label/regular-light': true;
    'btn-label/regular-light-underlined': true;
    'btn-label/regular-italic': true;
    'btn-label/regular-light-strike-through': true;
    'btn-label/regular-bold': true;
    'btn-label/regular-bold-underlined': true;
    'btn-label/small-light': true;
    'btn-label/small-strike-through': true;
    'btn-label/small-underlined': true;
    'btn-label/small-bold': true;
    'btn-label/small-bold-underline': true;
    'btn-label/extraSmall': true;
    'btn-label/extraSmall-bold': true;
  }
}

export const fontFamily = ['Inter', 'sans-serif'].join(',');

export const fontSizeLarge = 30;
export const fontSizeMedium = 24;
export const fontSizeSmall = 18;
export const fontSizeExtraSmall = 16;

export const fontWeightRegular = '400';
export const fontWeightBold = '600';
export const fontStyleItalic = 'italic';
export const lineHeightLarge = '1.3';
export const lineHeightRegular = '1';

const background = {
  default: '#f3f4f7',
};

const text = {
  primary: '#212121',
  secondary: '#606060',
};

const other = {
  // highContrast: '#212121',
  // mediumContrast: '#606060',
  // lowContrast: '#B9B9B9',
  component1: '#FFFFFF',
  component2: '#F0F6FF',
  plainWhite: '#FFFFFF',
  plainBlack: '#000000',
  outlines: '#EEEEEE',
  disabled: '#F0F2F6',
  disabledText: '#B9B9B9',
};

const info = {
  light: '#DEDEFC',
  main: '#7474F3',
  dark: '#4242EF',
};

const success = {
  light: '#DEFCED',
  main: '#74F3B3',
  dark: '#0FAD5E',
};

const error = {
  light: '#FCDEED',
  main: '#F374B3',
  dark: '#AD0F5E',
};

const warning = {
  light: '#FCEFDE',
  main: '#F3BC74',
  dark: '#AD680F',
};

// Override default theme shadows
const defaultTheme = createTheme();
const shadows = defaultTheme.shadows;
shadows[1] = '0px 4px 12px 0px rgba(20, 20, 20, 0.05)';
shadows[2] = '0px 4px 12px 0px rgba(20, 20, 20, 0.11)';
shadows[3] = '0px -3px 12px 0px rgba(0, 0, 0, 0.05)';

const borderRadius = 6;

const typographyStyles: TypographyOptions = {
  fontFamily, // Default font-family
  fontSize: fontSizeMedium,

  h1: {
    fontFamily,
    fontSize: fontSizeLarge,
    fontWeight: fontWeightRegular,
    lineHeight: lineHeightLarge,
    color: 'black',
  },
  'h1-bold': {
    fontFamily,
    fontSize: fontSizeLarge,
    fontWeight: fontWeightBold,
    lineHeight: lineHeightLarge,
  },
  'h1-italic': {
    fontFamily,
    fontSize: fontSizeLarge,
    fontWeight: fontWeightRegular,
    fontStyle: fontStyleItalic,
    lineHeight: lineHeightLarge,
  },
  h2: {
    fontFamily,
    fontSize: fontSizeMedium,
    fontWeight: fontWeightRegular,
    lineHeight: lineHeightLarge,
  },
  'h2-bold': {
    fontFamily,
    fontSize: fontSizeMedium,
    fontWeight: fontWeightBold,
    lineHeight: lineHeightLarge,
  },
  'h2-italic': {
    fontFamily,
    fontSize: fontSizeMedium,
    fontWeight: fontWeightRegular,
    fontStyle: fontStyleItalic,
    lineHeight: lineHeightLarge,
  },
  h3: {
    fontFamily,
    fontSize: fontSizeSmall,
    fontWeight: fontWeightRegular,
    lineHeight: lineHeightLarge,
  },
  'h3-bold': {
    fontFamily,
    fontSize: fontSizeSmall,
    fontWeight: fontWeightBold,
    lineHeight: lineHeightLarge,
  },
  'h3-italic': {
    fontFamily,
    fontSize: fontSizeSmall,
    fontWeight: fontWeightRegular,
    fontStyle: fontStyleItalic,
    lineHeight: lineHeightLarge,
  },
  h4: {
    fontFamily,
    fontSize: fontSizeExtraSmall,
    fontWeight: fontWeightRegular,
    lineHeight: lineHeightLarge,
  },
  'h4-bold': {
    fontFamily,
    fontSize: fontSizeExtraSmall,
    fontWeight: fontWeightBold,
    lineHeight: lineHeightLarge,
  },
  'h4-italic': {
    fontFamily,
    fontSize: fontSizeExtraSmall,
    fontWeight: fontWeightRegular,
    fontStyle: fontStyleItalic,
    lineHeight: lineHeightLarge,
  },
  'body/regular': {
    fontFamily,
    fontSize: fontSizeExtraSmall,
    fontWeight: fontWeightRegular,
    lineHeight: lineHeightLarge,
  },
  'body/regular-bold': {
    fontFamily,
    fontSize: fontSizeExtraSmall,
    fontWeight: fontWeightBold,
    lineHeight: lineHeightLarge,
  },
  'body/small': {
    fontFamily,
    fontSize: 14,
    fontWeight: fontWeightRegular,
    lineHeight: lineHeightLarge,
  },
  'body/small-bold': {
    fontFamily,
    fontSize: 14,
    fontWeight: fontWeightBold,
    lineHeight: lineHeightLarge,
  },
  'body/extraSmall': {
    fontFamily,
    fontSize: 12,
    fontWeight: fontWeightRegular,
    lineHeight: lineHeightLarge,
  },
  'body/extraSmall-bold': {
    fontFamily,
    fontSize: 12,
    fontWeight: fontWeightBold,
    lineHeight: lineHeightLarge,
  },
  'btn-label/big-light': {
    fontFamily,
    fontSize: fontSizeSmall,
    fontWeight: fontWeightRegular,
    lineHeight: lineHeightRegular,
  },
  'btn-label/big-italic': {
    fontFamily,
    fontSize: fontSizeSmall,
    fontWeight: fontWeightRegular,
    lineHeight: lineHeightRegular,
    fontStyle: fontStyleItalic,
  },
  'btn-label/big-bold': {
    fontFamily,
    fontSize: fontSizeSmall,
    fontWeight: fontWeightBold,
    lineHeight: lineHeightRegular,
  },
  'btn-label/regular-light': {
    fontFamily,
    fontSize: fontSizeExtraSmall,
    fontWeight: fontWeightRegular,
    lineHeight: lineHeightRegular,
  },
  'btn-label/regular-light-underlined': {
    fontFamily,
    fontSize: fontSizeExtraSmall,
    fontWeight: fontWeightRegular,
    lineHeight: lineHeightRegular,
    textDecoration: 'underline',
  },
  'btn-label/regular-italic': {
    fontFamily,
    fontSize: fontSizeExtraSmall,
    fontWeight: fontWeightRegular,
    lineHeight: lineHeightRegular,
    fontStyle: fontStyleItalic,
  },
  'btn-label/regular-light-strike-through': {
    fontFamily,
    fontSize: fontSizeExtraSmall,
    fontWeight: fontWeightRegular,
    lineHeight: lineHeightRegular,
    textDecoration: 'line-through',
  },
  'btn-label/regular-bold': {
    fontFamily,
    fontSize: fontSizeExtraSmall,
    fontWeight: fontWeightBold,
    lineHeight: lineHeightRegular,
  },
  'btn-label/regular-bold-underlined': {
    fontFamily,
    fontSize: fontSizeExtraSmall,
    fontWeight: fontWeightBold,
    lineHeight: lineHeightRegular,
    textDecoration: 'line-through',
  },
  'btn-label/small-light': {
    fontFamily,
    fontSize: 14,
    fontWeight: fontWeightRegular,
    lineHeight: lineHeightRegular,
  },
  'btn-label/small-strike-through': {
    fontFamily,
    fontSize: 14,
    fontWeight: fontWeightRegular,
    lineHeight: lineHeightRegular,
    textDecoration: 'line-through',
  },
  'btn-label/small-underlined': {
    fontFamily,
    fontSize: 14,
    fontWeight: fontWeightRegular,
    lineHeight: lineHeightRegular,
    textDecoration: 'underline',
  },
  'btn-label/small-bold': {
    fontFamily,
    fontSize: 14,
    fontWeight: fontWeightBold,
    lineHeight: lineHeightRegular,
  },
  'btn-label/small-bold-underline': {
    fontFamily,
    fontSize: 14,
    fontWeight: fontWeightBold,
    lineHeight: lineHeightRegular,
  },
  'btn-label/extraSmall': {
    fontFamily,
    fontSize: 12,
    fontWeight: fontWeightRegular,
    lineHeight: lineHeightRegular,
  },
  'btn-label/extraSmall-bold': {
    fontFamily,
    fontSize: 12,
    fontWeight: fontWeightBold,
    lineHeight: lineHeightRegular,
  },
};

export interface AppThemeConfig {
  primary: {
    main: string;
    light?: string;
    dark?: string;
  };
  secondary: {
    main: string;
    light?: string;
    dark?: string;
  };
  text?: {
    primary: string;
    secondary: string;
  };
  background?: {
    default: string;
  };
}

const createAppTheme = (config?: AppThemeConfig): Theme => {
  const primary = {
    light: '#DEDEFC',
    main: '#7474F3',
    dark: '#4242EF',
    contrastText: defaultTheme.palette.getContrastText('#7474F3'),
  };

  const secondary = {
    light: '#B0FFFB',
    main: '#00B2A9',
    dark: '#005D58',
    contrastText: defaultTheme.palette.getContrastText('#00B2A9'),
  };

  if (config) {
    primary.main = config.primary.main;
    primary.light = config.primary.light ?? lighten(primary.main, 0.2);
    primary.dark = config.primary.dark ?? darken(primary.main, 0.2);
    primary.contrastText = defaultTheme.palette.getContrastText(primary.main);

    secondary.main = config.secondary.main;
    secondary.light = config.secondary.light ?? lighten(secondary.main, 0.2);
    secondary.dark = config.secondary.dark ?? darken(secondary.main, 0.2);
    secondary.contrastText = defaultTheme.palette.getContrastText(secondary.main);

    if (config.text) {
      text.primary = config.text.primary;
      text.secondary = config.text.secondary;
    }

    if (config.background) {
      background.default = config.background.default;
    }
  }

  return createTheme(
    {
      // -------------------------------------------------------------------------
      // Shadows
      // -------------------------------------------------------------------------
      shadows,
      breakpoints: {
        values: {
          xs: 0,
          sm: 640,
          md: 900,
          lg: 1200,
          xl: 1536,
        },
      },

      // -------------------------------------------------------------------------
      // Shape
      // -------------------------------------------------------------------------
      shape: {
        borderRadius,
      },

      // -------------------------------------------------------------------------
      // Typography
      // -------------------------------------------------------------------------
      typography: {
        ...typographyStyles,
        body1: typographyStyles['body/regular'],
        body2: typographyStyles['body/regular-bold'],
        button: typographyStyles['btn-label/regular-bold'],
        caption: typographyStyles['body/extraSmall'],
        overline: typographyStyles['body/extraSmall-bold'],
        subtitle1: typographyStyles['body/small'],
        subtitle2: typographyStyles['body/small-bold'],
      },
      components: {
        MuiTypography: {
          defaultProps: {
            variantMapping: {
              'h1-bold': 'h1',
              'h1-italic': 'h1',
              'h2-bold': 'h2',
              'h2-italic': 'h2',
              'h3-bold': 'h3',
              'h3-italic': 'h3',
              'h4-bold': 'h4',
              'h4-italic': 'h4',
            },
          },
        },
        MuiAccordion: {
          styleOverrides: {
            root: {
              '&:before': {
                background: 'none',
              },
            },
          },
        },
        MuiAlert: {
          defaultProps: {
            sx: {
              typography: 'body/small',
            },
          },
          styleOverrides: {
            root: {
              '&.MuiAlert-standard': {
                alignItems: 'center',
                '&.MuiAlert-standardInfo': {
                  backgroundColor: info.light,
                  color: info.dark,
                  '& .MuiAlert-icon': {
                    color: info.dark,
                  },
                },
                '&.MuiAlert-standardError': {
                  backgroundColor: error.light,
                  color: error.dark,
                  '& .MuiAlert-icon': {
                    color: error.dark,
                  },
                },
                '&.MuiAlert-standardWarning': {
                  backgroundColor: warning.light,
                  color: warning.dark,
                  '& .MuiAlert-icon': {
                    color: warning.dark,
                  },
                },
                '&.MuiAlert-standardSuccess': {
                  backgroundColor: success.light,
                  color: success.dark,
                  '& .MuiAlert-icon': {
                    color: success.dark,
                  },
                },
              },
              '&.MuiAlert-filledInfo': {
                backgroundColor: other.plainWhite,
                border: '1px solid',
                color: secondary.contrastText,
                paddingY: '8px',
                '& .MuiAlert-message': {
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '4px',
                },
                '& .MuiAlert-icon': {
                  color: secondary.contrastText,
                },
                '& .MuiAlertTitle-root': {
                  marginBottom: 0,
                },
                '& .MuiAlert-action': {
                  paddingTop: 0,
                  '& .MuiIconButton-root': {
                    border: 'none',
                    height: '32px',
                    padding: 0,
                    marginRight: -5,
                    top: 5,
                    '&:hover': {
                      color: primary.dark,
                    },
                  },
                },
              },
            },
          },
        },
        MuiAlertTitle: {
          defaultProps: {
            sx: {
              typography: 'body/small-bold',
            },
          },
        },
        MuiAppBar: {
          defaultProps: {
            position: 'static',
            elevation: 0,
            sx: {
              borderRadius: { xs: 0, sm: 1 },
            },
          },
          styleOverrides: {
            root: {
              backgroundColor: other.component1,
              borderBottom: `1px solid ${other.outlines}`,
            },
          },
        },
        MuiAvatar: {
          styleOverrides: {
            root: {
              backgroundColor: other.component2,
              color: text.secondary,
              width: '44px',
              height: '44px',
            },
          },
        },
        MuiButton: {
          defaultProps: {
            variant: 'contained',
            disableElevation: true,
            disableRipple: true,
            tabIndex: 0,
          },
          styleOverrides: {
            root: {
              ...typographyStyles['btn-label/small-bold'],
              height: '40px',
              padding: '12px',
              minWidth: 'fit-content',
              textTransform: 'none',
              '&:hover': {
                boxShadow: 'none',
              },
              '&:disabled': {
                backgroundColor: other.disabled,
                border: '1px solid',
                borderColor: other.outlines,
                color: other.disabledText,
                boxShadow: 'none',
              },
            },
            outlinedSecondary: {
              backgroundColor: other.component2,
              borderColor: other.outlines,
              color: primary.dark,
              '&:hover': {
                borderColor: primary.main,
              },
              '&.MuiButton-sizeSmall': {
                fontSize: '14px',
                padding: '8px',
              },
            },
            containedSecondary: {
              backgroundColor: other.component2,
              border: '1px solid',
              borderColor: other.outlines,
              color: primary.dark,
              '&:hover': {
                backgroundColor: other.component2,
                borderColor: primary.main,
              },
              '&:disabled': {
                backgroundColor: other.plainWhite,
                borderColor: other.disabled,
                color: other.disabled,
              },
            },
            outlinedSuccess: {
              backgroundColor: success.light,
              border: '1px solid',
              borderColor: other.outlines,
              '&:hover': {
                borderColor: success.dark,
                backgroundColor: success.light,
              },
            },
            containedError: {
              backgroundColor: error.light,
              border: '1px solid',
              borderColor: error.light,
              color: error.dark,
              '&:hover': {
                backgroundColor: error.light,
                borderColor: error.dark,
              },
              '&:disabled': {
                backgroundColor: other.plainWhite,
                borderColor: other.disabled,
                color: other.disabled,
              },
            },
            outlined: {
              borderColor: other.outlines,
              color: primary.dark,
              '&.filter': {
                minHeight: '36px',
                padding: '8px',
              },
            },
            outlinedError: {
              // backgroundColor: error.light,
              border: '1px solid',
              borderColor: error.light,
              color: error.dark,
              '&:hover': {
                backgroundColor: error.light,
                borderColor: error.dark,
              },
              '&:disabled': {
                backgroundColor: other.plainWhite,
                borderColor: other.disabled,
                color: other.disabled,
              },
            },
          },
        },
        MuiButtonBase: {
          defaultProps: {
            disableRipple: true,
          },
        },
        MuiCheckbox: {
          defaultProps: {
            disableRipple: true,
            sx: {
              typography: 'btn-label/regular-light',
            },
          },
          styleOverrides: {
            root: {
              padding: '4px',
              '&.Mui-checked': {
                color: primary.dark,
              },
              '&.Mui-disabled': {
                color: other.disabled,
              },
            },
          },
        },
        MuiLink: {
          styleOverrides: {
            root: {
              textDecoration: 'none',
            },
          },
        },
        MuiListItemButton: {
          styleOverrides: {
            root: {
              gap: '8px',
              paddingLeft: 0,
              paddingRight: 0,
              '& > .MuiListItemIcon-root': {
                alignItems: 'center',
                justifyContent: 'center',
                minWidth: 'unset',
                width: '44px',
                height: '44px',
                borderRadius: '50%',
              },
              '&:hover': {
                backgroundColor: 'initial',
                color: primary.dark,
              },
            },
          },
        },
        MuiDivider: {
          styleOverrides: {
            root: {
              borderColor: other.outlines,
            },
          },
        },
        MuiDrawer: {
          styleOverrides: {
            paper: {
              maxHeight: '50svh',
              minHeight: '200px',
              '&.MuiDrawer-paperAnchorBottom': {
                display: 'flex',
                flexDirection: 'column',
                padding: '16px',
                borderTopLeftRadius: '16px',
                borderTopRightRadius: '16px',
              },
            },
          },
        },
        MuiFormControlLabel: {
          defaultProps: {
            disableTypography: true,
            sx: {
              typography: 'btn-label/regular-light',
              mx: 0,
            },
          },
          styleOverrides: {
            root: {
              ':has(.Mui-checked)': {
                color: primary.dark,
              },
              ':has(.Mui-disabled)': {
                color: other.disabled,
              },
            },
          },
        },
        MuiDialog: {
          defaultProps: {
            fullWidth: true,
          },
          styleOverrides: {
            paper: {
              borderRadius: '16px',
              maxHeight: '75svh',
              padding: '24px 16px',
              rowGap: '8px',
            },
          },
        },
        MuiDialogTitle: {
          defaultProps: {
            variant: 'h3-bold',
          },
          styleOverrides: {
            root: {
              padding: 0,
            },
          },
        },
        MuiDialogContent: {
          styleOverrides: {
            root: {
              fontSize: fontSizeExtraSmall,
              padding: '0',
            },
          },
        },
        MuiDialogActions: {
          styleOverrides: {
            root: {
              padding: '16px 0 0',
            },
          },
        },
        MuiIconButton: {
          defaultProps: {
            color: 'primary',
            disableRipple: true,
          },
          styleOverrides: {
            root: {
              border: '1px solid',
              borderRadius: borderRadius,
              height: '40px',
              width: '40px',
              '&:disabled': {
                backgroundColor: other.disabled,
                borderColor: other.outlines,
                color: other.disabledText,
              },
              '&.rounded': {
                borderColor: other.outlines,
                borderRadius: '50%',
                '&.MuiIconButton-sizeMedium': {
                  width: '32px',
                  height: '32px',
                },
                '&.MuiIconButton-sizeLarge': {
                  width: '44px',
                  height: '44px',
                },
              },
            },
            colorPrimary: {
              backgroundColor: primary.main,
              borderColor: primary.main,
              color: other.plainWhite,
              '&:hover': {
                backgroundColor: primary.dark,
              },
            },
            colorSecondary: {
              backgroundColor: other.component2,
              borderColor: other.outlines,
              color: primary.dark,
              '&:hover': {
                borderColor: primary.main,
              },
            },
            colorError: {
              backgroundColor: error.light,
              borderColor: error.light,
              color: error.dark,
              '&:hover': {
                borderColor: error.dark,
              },
              '&:disabled': {
                color: error.dark,
              },
            },
          },
        },
        MuiMenuItem: {
          styleOverrides: {
            root: {
              '&.MuiMenuItem-gutters': {
                paddingLeft: '8px',
                paddingRight: '8px',
                marginLeft: '8px',
                marginRight: '8px',
              },
              '&.Mui-selected, &:hover': {
                backgroundColor: 'initial',
                outline: '1px solid',
                outlineColor: other.outlines,
                borderRadius: borderRadius,
                boxSizing: 'border-box',
                '&:hover': {
                  backgroundColor: 'initial',
                },
              },
            },
          },
        },
        MuiModal: {
          defaultProps: {
            sx: {
              '&.players-drawer .MuiPaper-root': {
                height: { xs: '95svh', sm: 'min(750px,75svh)' },
                maxHeight: 'unset',
                '& > div:nth-of-type(2)': {
                  overflowY: 'hidden',
                  height: '100%',
                },
              },
            },
          },
        },
        MuiOutlinedInput: {
          styleOverrides: {
            input: {
              paddingLeft: '8px',
            },
          },
        },
        MuiSvgIcon: {
          styleOverrides: {
            root: {
              '&.MuiSvgIcon-fontSizeMedium': {
                ...typographyStyles['btn-label/regular-light'],
                fontSize: '24px',
              },
            },
          },
        },
        MuiToolbar: {
          styleOverrides: {
            root: {
              paddingTop: '12px',
              paddingBottom: '12px',
            },
          },
        },
      },
      // -------------------------------------------------------------------------
      // Palette
      // -------------------------------------------------------------------------
      palette: {
        text: {
          primary: text.primary,
          secondary: text.secondary,
        },
        primary: {
          light: primary.light,
          main: primary.main,
          dark: primary.dark,
        },
        secondary: {
          // ! native app colors
          light: secondary.light,
          main: secondary.main,
          dark: secondary.dark,
        },
        background: {
          default: background.default,
        },
        success: {
          main: success.main,
          dark: success.dark,
          light: success.light,
        },
        info: {
          main: info.main,
          dark: info.dark,
          light: info.light,
        },
        error: {
          main: error.main,
          dark: error.dark,
          light: error.light,
        },
        warning: {
          main: warning.main,
          dark: warning.dark,
          light: warning.light,
        },
        other: {
          // highContrast: text.primary,
          // mediumContrast: text.secondary,
          // lowContrast: other.lowContrast,
          component1: other.component1,
          component2: other.component2,
          plainWhite: other.plainWhite,
          outlines: other.outlines,
          disabled: other.disabled,
          disabledText: other.disabledText,
        },
        mode: 'light',
      },
    },
    nlNL,
  );
};

export default createAppTheme;
